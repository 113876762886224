<template>
  <div class="brandCom-class">
    <el-cascader
      v-model="brand_id"
      :options="brandList"
      clearable
      filterable
      :popper-class="popperClass"
      :props="{
        checkStrictly: true,
        emitPath: false,
        value: 'id',
        label: 'brand_name_txt',
        multiple: duoxuan,
      }"
      :collapse-tags="tags"
      :placeholder="place"
      :show-all-levels="!showEnd"
      @change="changeBrandList"
    >
      <template #default="{ data }">
        <span>{{ data.brand_name }}</span>
      </template>
    </el-cascader>
    <!-- <el-select
      v-model="brand_id"
      clearable
      placeholder="选择品牌"
      style="width: 160px"
      @change="changeBrandList"
    >
      <el-option
        v-for="item in brandList"
        :key="item.id"
        :label="item.brand_name"
        :value="item.id"
      ></el-option>
    </el-select> -->
  </div>
</template>
<script>
  import { brandList } from '@/api/selectList'
  export default {
    name: 'BrandSelect',
    components: {},
    props: {
      showEnd: {
        type: Boolean,
        default: () => false,
      },
      // 是否可多选
      duoxuan: {
        type: Boolean,
        default: () => false,
      },
      tags: {
        type: Boolean,
        default: () => false,
      },
      place: {
        type: String,
        default: () => '请选择品牌',
      },
      popperClass: {
        type: String,
        default: () => '',
      },
      brandId: {
        type: [Number, Array],
        default: () => null,
      },
    },
    data() {
      return {
        brand_id: this.brandId,
        brandList: [],
      }
    },
    computed: {},
    watch: {
      brandId(val) {
        this.brand_id = val
      },
      // brand_id(val) {
      //   if (val && val.length != 0) {
      //     if (!this.duoxuan) {
      //       this.$emit('brand-select-change', val[val.length - 1])
      //     } else {
      //       this.$emit('brand-select-change', val)
      //     }
      //   } else {
      //     this.$emit('brand-select-change', '')
      //   }
      // },
    },
    created() {
      console.log('branda created')
      this.fetchData()
      // this.brand_id = Number(this.brandId)
    },
    updated() {
      console.log('branda updated')
    },
    beforeDestroy() {
      console.log('brand组件销毁前')
    },

    destroyed() {
      console.log('brand组件销毁后')
    },
    mounted() {
      console.log('brand mounted')
    },
    methods: {
      async fetchData() {
        let { data, msg, code } = await brandList()
        if (code == 200) {
          console.log('递归开始', data)
          // this.getTreeData(data)
          console.log(data)

          this.brandList = data
        } else {
          this.$message.error(msg)
        }
      },
      // 递归处理空children数组
      getTreeData(arr) {
        arr.forEach((item) => {
          if (item.children.length == 0) {
            item.children = undefined
          } else {
            this.getTreeData(item.children)
          }
        })
      },
      resetForm() {
        this.brand_id = ''
      },
      changeBrandList(val) {
        console.log(val)
        if (this.duoxuan) {
          // 多选事件
          this.$emit('brand-select-change', val.join())
          this.$emit('change-brand', val.join())
        } else {
          this.$emit('brand-select-change', val)
          this.$emit('change-brand', val)
        }
        // if (val) {
        //   if (val.length == 0) {
        //     this.$emit('brand-select-change', '')
        //   } else {
        //     if (!this.duoxuan) {
        //       this.$emit('brand-select-change', val[val.length - 1])
        //     } else {
        //       // 多选事件
        //       console.log('多选事件', val)
        //       this.$emit('brand-select-change', val)
        //     }
        //   }
        // }
      },
    },
  }
</script>
<style lang="scss" scoped></style>
