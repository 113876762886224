<template>
  <div class="wrapper-com">
    <el-cascader
      v-model="area_id_"
      :options="areaList"
      clearable
      :popper-class="popperClass"
      :placeholder="place"
      :show-all-levels="onlyCheckEnd"
      :props="{ checkStrictly: onlyCheckEnd, value: 'id', label: 'name' }"
      style="width: 160px"
      @change="changeBrandList"
    ></el-cascader>
  </div>
</template>
<script>
  import { saleAreaList } from '@/api/selectList'

  export default {
    name: 'SaleAreaSelectTree',
    components: {},
    props: {
      place: {
        type: String,
        default: () => '请选择销售区域',
      },
      popperClass: {
        type: String,
        default: () => '',
      },
      onlyCheckEnd: {
        type: Boolean,
        default: () => true,
      },
    },
    data() {
      return {
        area_id_: '', //未处理的ID
        area_id: '',
        areaList: [],
      }
    },
    computed: {},
    watch: {},
    created() {
      this.getAreaList()
    },
    mounted() {},
    methods: {
      // 处理的树  选择区域 id
      changeBrandList(val) {
        if (val.length !== 0) {
          this.area_id = val[val.length - 1]
        } else {
          this.area_id = ''
        }
        this.$emit('set-sale-select-id', this.area_id)
      },
      // 递归处理空 children
      treeData(arr) {
        arr.forEach((item) => {
          if (item.children.length == 0) {
            item.children = undefined
          } else {
            this.treeData(item.children)
          }
        })
        return arr
      },
      async getAreaList() {
        let { data, code, msg } = await saleAreaList()
        console.log(data)
        if (code == 200) {
          // let treeData = data
          // this.areaList = this.treeData(data)
          this.areaList = data
        } else {
          this.$message.error(msg)
        }
      },
      resetForm() {
        this.area_id = ''
        this.area_id_ = '' //未处理的ID
      },
    },
  }
</script>
<style lang="scss" scoped></style>
