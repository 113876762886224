import request from '@/utils/request'

// 后台商品排行榜
export function goodsList(data) {
  return request({
    url: '/saleAdmin/rank/goods-top',
    method: 'post',
    data,
  })
}

// 后台业务员排行榜
export function staffList(data) {
  return request({
    url: '/saleAdmin/rank/user-top',
    method: 'post',
    data,
  })
}

// 后台客户排行榜
export function custList(data) {
  return request({
    url: '/saleAdmin/rank/customer-top',
    method: 'post',
    data,
  })
}
// 获取商品销售类型
export function saleTypeList(data) {
  return request({
    url: '/saleAdmin/sale-form/goods-type-arr',
    method: 'post',
    data,
  })
}

/**
 * 销售明细
 */

// 销售明细按单据
export function saleDetailList(data) {
  return request({
    url: '/saleAdmin/sale-form/order',
    method: 'post',
    data,
  })
}
/**
 * 销售汇总按商品
 */

// 销售明细按商品
export function saleGoodsList(data) {
  return request({
    url: '/saleAdmin/sale-form/goods-new',
    method: 'post',
    data,
  })
}
/**
销售汇总按客户
 */

// 销售明细按客户
export function saleClientList(data) {
  return request({
    url: '/saleAdmin/sale-form/customer',
    method: 'post',
    data,
  })
}

// 销售明细按业务员
export function saleStaffList(data) {
  return request({
    url: '/saleAdmin/sale-form/staff',
    method: 'post',
    data,
  })
}
