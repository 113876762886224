var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper-com" },
    [
      _c("el-cascader", {
        staticStyle: { width: "160px" },
        attrs: {
          options: _vm.areaList,
          clearable: "",
          "popper-class": _vm.popperClass,
          placeholder: _vm.place,
          "show-all-levels": _vm.onlyCheckEnd,
          props: {
            checkStrictly: _vm.onlyCheckEnd,
            value: "id",
            label: "name",
          },
        },
        on: { change: _vm.changeBrandList },
        model: {
          value: _vm.area_id_,
          callback: function ($$v) {
            _vm.area_id_ = $$v
          },
          expression: "area_id_",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }