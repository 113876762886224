var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "brandCom-class" },
    [
      _c("el-cascader", {
        attrs: {
          options: _vm.brandList,
          clearable: "",
          filterable: "",
          "popper-class": _vm.popperClass,
          props: {
            checkStrictly: true,
            emitPath: false,
            value: "id",
            label: "brand_name_txt",
            multiple: _vm.duoxuan,
          },
          "collapse-tags": _vm.tags,
          placeholder: _vm.place,
          "show-all-levels": !_vm.showEnd,
        },
        on: { change: _vm.changeBrandList },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var data = ref.data
              return [_c("span", [_vm._v(_vm._s(data.brand_name))])]
            },
          },
        ]),
        model: {
          value: _vm.brand_id,
          callback: function ($$v) {
            _vm.brand_id = $$v
          },
          expression: "brand_id",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }